import * as React from 'react'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const ExplosionPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "explosion damage repair services",
	  "alternateName": "explosion restoration services",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Asbestos Removal Services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "Explosion Damage Repair and Restoration Services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Explosion Damage Repair Services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Explosion Restoration Services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="Explosion Damage Repair Services"
        description="Ecotech will assess the damage, repair and restore your home back to it’s as-new condition."
        canonical="https://ecotechrestoration.ca/services/explosion-damage-repair-and-restoration/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/explosion.jpg"
          quality={95}
          alt="Explosion Damage Repair Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          Explosion Damage Repair &amp; Restoration
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>
          Do you have a Explosion Damage Repair or Restoration Emergency?
        </CallOut>
        <p>
          While it’s rare, an explosion in or around your home can happen. Most
          often it’s the result of gas leak, an appliance malfunction or someone
          digging in their yard without first checking with Fortis-BC. But
          whatever the cause, Ecotech Restoration is able to assess the damage,
          repair and restore your home back to it’s as-new condition.
        </p>
        <SplashTwo title="Explosion Damage Repairs" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default ExplosionPage
